import classnames from "classnames";
import ArrowHeading from "components/Icons/Arrowheading";
import HomeArrow from "components/Icons/HomeArrow";
import IconLogout from "components/Icons/Logout";
import IconMenu from "components/Icons/Menu";
import Switch from "components/Icons/Switch";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import Loader from "components/Loader";
import Logo, { LogoTypes } from "components/LogoNav";
import MenuItem from "components/MenuItem";
import MenuListItems from "components/MenuListItems";
import MenuSeparator from "components/MenuSeparator";
import { ResponsiveType } from "consts/responsive";
import PropTypes from "prop-types";
import React, { Component, Fragment } from "react";
import { Trans, withTranslation } from "react-i18next";
import { NavLink } from "react-router-dom";
import { CSSTransition } from "react-transition-group";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

const menu = window.menuConfig;
import SwitchLanguage from "components/SwitchLanguage";
import consts from "consts/consts";
import { getFromLocal } from "helpers/localStorage";
import { Translate } from "helpers/utility";

const squareLogo = "/assets/logo_nav.png";
const rectangleLogo = "/assets/logo_horizontal_nav.png";
class Menu extends Component {
  state = {
    showAccountMenu: false,
    showLanguageSelect: false,
    showSubDropdownLang: false,
  };

  handleArrowClick() {
    const { showAccountMenu } = this.state;
    this.setState({ showAccountMenu: !showAccountMenu });
  }

  handleShowSubDropdownLang() {
    this.setState({ showSubDropdownLang: !this.state.showSubDropdownLang });
  }

  render() {
    const {
      rootClassName,
      isOpened,
      onToggleClick,
      profileInfo,
      isConnected,
      userInfo,
      onSwitchProfile,
      t,
    } = this.props;
    const { showAccountMenu, showSubDropdownLang } = this.state;
    const direction = getFromLocal("layoutDirection");
    const isSafari = getFromLocal("isSafari");
    return (
      <div
        className={classnames(
          rootClassName,
          style.container.concat(" ", direction.toUpperCase()),
          {
            [style.opened]: isOpened,
            [style.closed]: !isOpened,
          }
        )}
      >
        <div className={style.horizontalMenu}>
          <IconMenu
            className={classnames(style.iconMenu, {
              [style[direction?.toUpperCase()]]: true,
            })}
            onClick={onToggleClick}
          />
          {isSafari ? (
            <NavLink
              to={consts.routes.home.url}
              style={{
                width: 92,
                height: 46,
                backgroundImage: "url(/assets/logo_horizontal_nav.png)",
                backgroundSize: "contain",
                display: "inline-block",
                marginTop: 7,
                marginLeft: 50,
                position: direction.toLowerCase() === "rtl" ? "relative" : "",
                right: direction.toLowerCase() === "rtl" ? 50 : "",
              }}
            />
          ) : (
            <NavLink to={consts.routes.home.url}>
              <Logo
                type={LogoTypes.RECTANGLE}
                className={classnames(style.horizontalLogo, {
                  [style.horizontalLogoRTL]: direction.toLowerCase() === "rtl",
                })}
              />
            </NavLink>
          )}
        </div>
        <div className={style.verticalMenu}>
          {isSafari ? (
            <NavLink
              className={style.safariLogoBackground}
              to={consts.routes.home.url}
              style={{
                width: isOpened
                  ? consts.menuLogo.logoTypeWhenOpen === LogoTypes.SQUARE
                    ? 60
                    : 120
                  : 60,
                height: 60,
                backgroundImage: isOpened
                  ? consts.menuLogo.logoTypeWhenOpen === LogoTypes.SQUARE
                    ? `url(${squareLogo})`
                    : `url(${rectangleLogo})`
                  : consts.menuLogo.logoTypeWhenClosed === LogoTypes.SQUARE
                  ? `url(${squareLogo})`
                  : `url(${rectangleLogo})`,
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat",
                display: "inline-block",
                margin: 20,
                transition: "width 0.5s",
              }}
            />
          ) : (
            <NavLink
              to="/"
              onClick={() => {
                this.setState({ showAccountMenu: false });
              }}
            >
              {isOpened ? (
                consts.menuLogo.logoTypeWhenOpen === LogoTypes.SQUARE ? (
                  <Logo className={style.logo} />
                ) : (
                  <Logo
                    type={LogoTypes.RECTANGLE}
                    className={style.horizontalLogoMenu}
                  />
                )
              ) : consts.menuLogo.logoTypeWhenClosed === LogoTypes.SQUARE ? (
                <Logo className={style.logo} />
              ) : (
                <Logo
                  type={LogoTypes.RECTANGLE}
                  className={classnames(
                    style.horizontalLogoMenu,
                    style.horizontalLogoMenuClosed
                  )}
                />
              )}
            </NavLink>
          )}
          {!isConnected && (
            <MenuItem
              onClick={this.props.showLoginPopup}
              icon={
                <img
                  src={"/assets/profile_default.png"}
                  className={style.accountImage}
                  alt="Account"
                />
              }
              expanded={isOpened}
              exact={false}
            >
              <span className={style.accountName}>
                <Trans>Log in</Trans>
              </span>
            </MenuItem>
          )}
          {isConnected && (
            <Fragment>
              {consts.appModules.profiles === true ? (
                <MenuItem
                  to={"#"}
                  onClick={onSwitchProfile}
                  icon={
                    <div className={style.profilImage}>
                      {profileInfo &&
                      profileInfo.getPicture() &&
                      profileInfo.getPicture() !== null ? (
                        <Image
                          width={50}
                          height={50}
                          src={`${consts.image_hostname}${
                            routes.imgdata
                          }?type=profile&objectId=${profileInfo.getPicture()}&format_w=50&format_h=50&languageId=${getFromLocal(
                            "languageId"
                          )}`}
                          ratio={ImageRatio.CIRCLE}
                          placeholder={PlaceholderType.PROFILE}
                        />
                      ) : (
                        <div className={style.profilImagePlaceholder}></div>
                      )}
                      <Switch className={style.switchIcon} />
                    </div>
                  }
                  expanded={isOpened}
                  exact={false}
                >
                  {!profileInfo ? (
                    <div className={style.loader}>
                      <Loader />
                    </div>
                  ) : (
                    <span className={style.profilName}>
                      {profileInfo.getName()}
                    </span>
                  )}
                </MenuItem>
              ) : (
                <MenuItem
                  to={consts.routes.account.url}
                  icon={
                    <div
                      className={classnames(
                        style.profilImagePlaceholder,
                        style.accountImage
                      )}
                    />
                  }
                  expanded={isOpened}
                  exact={false}
                >
                  {!userInfo ? (
                    <div className={style.loader}>
                      <Loader />
                    </div>
                  ) : (
                    <span className={style.accountName}>
                      {userInfo.getFirstname() + " " + userInfo.getLastname()}
                    </span>
                  )}
                </MenuItem>
              )}
              <div
                className={style.changeMenuContainer}
                onClick={this.handleArrowClick.bind(this)}
              >
                <span className={style.changeMenu}>
                  <ArrowHeading
                    className={classnames(style.arrow, {
                      [style.showMenu]: showAccountMenu,
                      [style.hideMenu]: !showAccountMenu,
                    })}
                  />
                </span>
                <CSSTransition
                  in={isOpened}
                  timeout={1000}
                  classNames={{
                    enter: style.labelEnter,
                    enterActive: style.labelEnterActive,
                    enterDone: style.labelEnterDone,
                    exit: style.labelExit,
                    exitActive: style.labelExitActive,
                    exitDone: style.labelExitDone,
                  }}
                >
                  <span
                    className={classnames(style.label, {
                      [style.lableRTL]: direction === "rtl",
                    })}
                  >
                    {showAccountMenu ? t("Hide") : t("Show")}{" "}
                    <Translate t={t}>user settings</Translate>
                  </span>
                </CSSTransition>
              </div>
            </Fragment>
          )}
          <MenuSeparator />
          <div
            className={classnames({
              [style.scrollMenuContainerConnected]: isConnected,
              [style.scrollMenuContainerConnectedRTL]:
                isConnected && direction === "rtl",
              [style.scrollMenuContainerGuest]: !isConnected,
            })}
          >
            {!showAccountMenu && (
              <MenuListItems
                menu={menu.app}
                onClick={() => {
                  if (this.props.viewport.type !== ResponsiveType.DESKTOP) {
                    onToggleClick();
                  }
                }}
                isOpened={isOpened}
                isConnected={isConnected}
              />
            )}
            {showAccountMenu && isConnected && (
              <MenuListItems
                menu={menu.account}
                onClick={() => {
                  if (this.props.viewport.type !== ResponsiveType.DESKTOP) {
                    onToggleClick();
                  }
                }}
                isOpened={isOpened}
              />
            )}
          </div>
          <div className={style.bottomContainer}>
            {consts.enum.languageId?.length > 1 && (
              <div
                className={classnames(
                  style.switchLanguage,
                  !isOpened ? style.shrinked : "",
                  !isOpened && !showSubDropdownLang
                    ? style.overHiddingLang
                    : null
                )}
              >
                <SwitchLanguage
                  handleShowSubDropdownLang={this.handleShowSubDropdownLang.bind(
                    this
                  )}
                />
              </div>
            )}
            {isConnected && (
              <>
                <MenuSeparator />
                <MenuItem
                  to="/logout"
                  icon={<IconLogout />}
                  expanded={isOpened}
                >
                  <Translate t={t}>Log out</Translate>
                </MenuItem>
              </>
            )}
          </div>
        </div>
        <button
          className={classnames(style.toggleButton, {
            [style.leftPosition]: direction !== "rtl",
            [style.rightPosition]: direction === "rtl",
          })}
          onClick={onToggleClick}
        >
          <HomeArrow
            className={classnames(style.icon, {
              [style.openIcon]: !isOpened,
              [style.closeIcon]: isOpened,
              [style.openIconToRight]: !isOpened && direction === "rtl",
              [style.closeIconToRight]: isOpened && direction === "rtl",
            })}
          />
        </button>
      </div>
    );
  }
}

Menu.defaultProps = {
  isOpened: false,
};

Menu.propTypes = {
  isOpened: PropTypes.bool,
  userInfo: PropTypes.any,
};

export default withTranslation()(Menu);
