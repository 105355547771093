import { addToast } from "actions/ui";
import classnames from "classnames";
import Button, { ButtonType } from "components/Button";
import Checkbox from "components/Checkbox";
import ErrorMessage from "components/ErrorMessage";
import ArrowHeading from "components/Icons/Arrowheading";
import Image, { ImageRatio, PlaceholderType } from "components/Image";
import Loader from "components/Loader";
import Modal from "components/Modal";
import RadioCard from "components/RadioCard";
import SubscriptionItem from "components/SubscriptionItem";
import VoucherZone from "components/VoucherZone";
import consts from "consts/consts";
import FormChangePurchaseCode from "containers/FormChangePurchaseCode";
import ModalValidatePurchaseCode from "containers/ModalValidatePurchaseCode";
import { sendAnalytics } from "helpers/analytics";
import IngenicoModel from "helpers/ingenicoModel";
import { getFromLocal } from "helpers/localStorage";
import {
  checkOrderstatus,
  formatPrice,
  isIOS,
  isIpadOS,
} from "helpers/utility";
import React, { Component } from "react";
import Collapsible from "react-collapsible";
import { Trans, withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { compose } from "redux";
import { TucanoActions, TucanoModels, TucanoSelectors } from "web-api/main";

import routes from "../../web-api/consts/routes";
import style from "./style.module.css";

class ModalSubscription extends Component {
  constructor(props) {
    super(props);

    this.paymentForm = null;
    this.previousUrl = null;
    this.state = {
      currentStep: 0,
      endProcess: true,
      showPurchaseControl: false,
      showEditPurchaseCode: undefined,
      isConfirmOnly: false,
      proceed: false,
      isOld: false,
      useCode: true,
      purchaseCode: "",
      steps: [
        {
          name: "Choose a subscription",
          data: [],
        },
        {
          name: "Confirm your order",
          data: {
            voucherCode: "",
            validVoucher: undefined,
          },
        },
        {
          name: "Choose your payment method",
          data: {
            paymentAlias: "",
            paymentBrand: "",
            paymentMethod: "",
          },
        },
      ],
      productId: getFromLocal("productId") || consts.productId,
      voucherOnlyOption: false,
      subscriptionPrice: "",
    };

    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
  }
  togglePurchaseCodeModal = () => {
    this.setState({ showEditPurchaseCode: !this.state.showEditPurchaseCode });
  };
  toggleUseCode = async () => {
    if (this.state.useCode) {
      await this.setState({ isConfirmOnly: true });
      await this.ToggleModalPurchaseControl();
    }
    this.setState({ useCode: !this.state.useCode }, () => {
      //FIXME: implement resetPurchaseCode
      // this.props.dispatch(TucanoActions.resetPurchaseCode());
    });
  };
  static fetch(props) {
    const productId = getFromLocal("productId") || consts.productId;
    props.dispatch(
      TucanoActions.getAvailableOffers(
        getFromLocal("languageId"),
        productId,
        undefined
      )
    );

    if (
      props.item instanceof TucanoModels.Asset &&
      props.item.isCatchupAsset === false &&
      props.item.getType() !== "channel_logo"
    ) {
      props.dispatch(TucanoActions.testAsset(props.item.id));
    }
  }

  componentDidUpdate() {
    // console.log("optionValidity", prevProps.optionValidity, this.props.optionValidity)
    // if (prevProps.optionValidity !== this.props.optionValidity) {
    //   // successful purchase
    //   this.setState({
    //     currentStep: 3,
    //   });
    // }
    if (
      this.state.currentStep === 3 &&
      !this.props.isLoadingAskPayment &&
      !this.props.isLoadingBuyAsset &&
      !this.props.isLoadingCheckOrderStatus &&
      this.props.paymentStatus === "executed"
    ) {
      let clearSetTimeOut = setTimeout(() => {
        this.setState({ endProcess: false });
        clearTimeout(clearSetTimeOut);
      }, 5000);
    }

    if (
      this.state.currentStep === 3 &&
      this.props.paymentStatus === "cancelled"
    ) {
      this.handleClose();
    }
  }

  async componentDidMount() {
    const isSafari = getFromLocal("isSafari");
    ModalSubscription.fetch(this.props);
    this.props.dispatch(TucanoActions.getAccountDetails());
    const { accountDetail } = this.props;
    if (accountDetail)
      await this.setState({
        useCode:
          typeof plusInfo === "string"
            ? JSON.parse(accountDetail["hasPurchaseCode"])
            : accountDetail["hasPurchaseCode"],
        currentStep: isSafari && (isIOS() || isIpadOS()) ? -1 : 0,
      });
    this.paymentForm = document.querySelector(".form-payment");
  }

  componentWillUnmount() {
    this.props.dispatch(TucanoActions.resetPlayerInfos());
    this.paymentForm.removeAttribute("action");
    this.paymentForm.removeAttribute("method");
    this.paymentForm.removeAttribute("target");
    this.paymentForm.innerHTML = "";
    this.props.dispatch(TucanoActions.resetOrderStatus());
    this.props.dispatch(TucanoActions.resetVoucherValidation());
  }

  handleClose() {
    if (this.props.onCloseClick) {
      this.props.onCloseClick();
    }
  }

  handleConfirm() {
    const { item, dispatch, history } = this.props;

    if (item && history.location.pathname.includes("player")) {
      let audioLang, subtitleLang;
      if (item.lang) {
        audioLang = item.lang[0].audio;
        subtitleLang = item.lang[0].sub;
      }
      dispatch(TucanoActions.readAsset(item.id, audioLang, subtitleLang)).then(
        () => {
          this.props.onConfirmClick();
        }
      );
    } else if (this.props.onConfirmClick) {
      this.props.onConfirmClick();
    }
  }

  errorAPIAddOptions = {
    "-163": "Missing parameter optionIds",
    "-1000": "Wrong parameter format",
    "-1100":
      "Try to add an option depending on another, not yet linked to customer sale",
    "-1101": "Data consistency error",
    "-1102": "A subscription is already active on your account",
    "-1103": "This offer is not compatible with your active subscription",
    "-1106": "Error on option’s availability check",
    "-1112": "Error on option’s orderable check",
    "-2332": "No product add on the sale,Please, addProducts before addOptions",
    "-2333": "You can subscribe to this offer only once",
    "-2336": "You can subscribe to this offer only once",
    "-2345": "This offer is not compatible with your product",
    "-3002": "Parameter optionIds not valid",
  };

  errorAPIAskPayment = {
    "-500": "No payment needed for this offer",
    "-501": "Please accept terms and conditions to continue",
    "-911": "This voucher is not valid",
    "-913": "Voucher validation timeout",
    "-1608": "This offer can only be activated with a valid voucher",
    "-2108": "Missing parameter paymentData",
    "-2109": "paymentData not valid",
    "-2110": "Unable to find payment mode",
    "-2111": "Payment mode not active",
  };

  errorAPIBuyAsset = {
    "-997": "Portal module not found",
    "-1500": "Id is required",
    "-1502": "Price is required",
    "-1600": "Asset not found",
    "-1604": "Asset already available",
    "-1605": "Asset not active",
    "-1609": "Invalid voucher code",
    "-1611":
      "This asset cannot be bought because there is no validity hours defined for it’s catalog model",
    "-1612": "This catalogue is not linked to this commercial model",
    "-1615": "Pin not valid",
    "-1616": "Option not found",
    "-1617": "Error external system",
    "-1618": "No option available",
    "-2207": "Access denied for this asset (Option not valid)",
    "-2209": "Voucher is not valid for this asset",
    "-2409": "Customer Suspended",
  };

  getErrorAPI(api, code) {
    let error = "An error occured,Please retry later";

    switch (api) {
      case "addOptions":
        if (this.errorAPIAddOptions[code]) {
          error = this.errorAPIAddOptions[code];
        }
        break;
      case "askPayment":
        if (this.errorAPIAskPayment[code]) {
          error = this.errorAPIAskPayment[code];
        }
        break;
      case "buyAsset":
        if (this.errorAPIBuyAsset[code]) {
          error = this.errorAPIBuyAsset[code];
        }
        break;
      default:
        break;
    }

    return error;
  }

  _next() {
    const { currentStep, steps, voucherOnlyOption, subscriptionPrice } =
      this.state;
    const { t } = this.props;
    if (currentStep === 1 && voucherOnlyOption === true) {
      if (
        steps[currentStep].data.voucherCode !== "" &&
        steps[currentStep].data.validVoucher === true
      ) {
        this.handleOnAddOptions();
      } else {
        this.props.dispatch(
          addToast({
            text: t("Please provide a voucher code"),
            className: "error",
            duration: 6000,
          })
        );
      }
    } else if (currentStep === 1 && subscriptionPrice === 0) {
      this.handleOnAddOptions();
      //this.setState({ currentStep: currentStep + 2 });
    } else {
      this.setState({
        currentStep: currentStep + 1,
      });
    }
  }

  _prev() {
    const { currentStep } = this.state;

    this.setState({
      currentStep: currentStep - 1,
    });
  }

  get closeButton() {
    const { currentStep } = this.state;
    if (
      currentStep === 3 &&
      !this.props.isLoadingAskPayment &&
      !this.props.isLoadingBuyAsset &&
      !this.props.isLoadingCheckOrderStatus &&
      this.props.paymentStatus === "executed"
    ) {
      return (
        <Button
          type={ButtonType.NORMAL}
          rootClassName={style.closeButton}
          onClick={this.handleConfirm.bind(this)}
        >
          <Trans>Close</Trans>
        </Button>
      );
    } else {
      return null;
    }
  }

  get previousButton() {
    const { currentStep } = this.state;

    if (currentStep === 1 || currentStep === 2) {
      return (
        <Button
          type={ButtonType.GHOST}
          rootClassName={style.backButton}
          onClick={this._prev}
        >
          <Trans>Back</Trans>
        </Button>
      );
    } else {
      return null;
    }
  }

  get nextButton() {
    const { isLoadingAskPayment, isLoadingBuyAsset } = this.props;
    const { currentStep, steps } = this.state;

    let buttonType = ButtonType.NORMAL;

    switch (currentStep) {
      case -1:
        return (
          <Button
            rootClassName={style.nextButton}
            type={buttonType}
            onClick={this._next}
          >
            <Trans>Next</Trans>
          </Button>
        );
      case 0:
        //Selection option
        return null;
      case 1:
        //Encode voucher
        return (
          <>
            {consts.manageSubscription && (
              <Button
                rootClassName={style.nextButton}
                type={
                  this.state.voucherCode
                    ? this.state.validVoucher
                      ? buttonType
                      : ButtonType.DISABLED
                    : buttonType
                }
                onClick={this._next}
              >
                <Trans>Confirm</Trans>
              </Button>
            )}
          </>
        );
      case 2:
        //Selection payment method
        buttonType =
          !steps[currentStep].data ||
          steps[currentStep].data.length === 0 ||
          !steps[currentStep].data.paymentMethod
            ? ButtonType.DISABLED
            : ButtonType.NORMAL;

        return (
          <Button
            type={
              isLoadingAskPayment || isLoadingBuyAsset
                ? ButtonType.LOADING
                : buttonType
            }
            rootClassName={style.nextButton}
            onClick={this.handleOnAddSubscription.bind(this)}
          >
            <Trans>Go to payment</Trans>
          </Button>
        );
      default:
        return null;
    }
  }

  handleSubscription(value) {
    const steps = { ...this.state.steps };

    steps[0].data = value;
    if (steps[0].data.type === "voucherOnly") {
      this.setState({ voucherOnlyOption: true });
    } else {
      this.setState({ voucherOnlyOption: false });
    }

    if (steps[0].data instanceof TucanoModels.OptionAvailability) {
      this.setState({ subscriptionPrice: value.getPriceWithVat() });
    }

    this.setState({
      steps: Object.assign([], steps),
    });

    this._next();
    this.props
      .dispatch(TucanoActions.getListPaymentMethods(getFromLocal("languageId")))
      .then(() => {});
  }

  setVoucherCode(voucherCode, validVoucher, optionVoucher) {
    if (validVoucher && optionVoucher) {
      const steps = { ...this.state.steps };

      let paymentCopy = steps[1].data;

      paymentCopy.voucherCode = voucherCode;
      paymentCopy.validVoucher = validVoucher;
      steps[1].data = paymentCopy;
      this.setState({
        steps: Object.assign([], steps),
      });
    }
  }

  selectSubscriptionPaymentBrandOld(paymentMethod) {
    const steps = { ...this.state.steps };
    const step = steps[2].data;
    steps[2].data = {
      ...step,
      ...{
        brandBillingProviderId: paymentMethod.brandBillingProviderId,
        paymentMethod: paymentMethod.paymentMethod,
        paymentBrand: paymentMethod.paymentBrand,
        paymentAlias: paymentMethod.paymentAlias,
      },
    };
    this.setState({
      steps: Object.assign([], steps),
    });
  }
  selectSubscriptionPaymentBrand(paymentMethod) {
    const steps = { ...this.state.steps };
    const step = steps[2].data;
    steps[2].data = {
      ...step,
      ...{
        brandBillingProviderId: paymentMethod.brandBillingProviderId,
        paymentMethod: paymentMethod.paymentBrand,
        paymentBrand: paymentMethod.paymentBrand,
        paymentAlias: paymentMethod.paymentAlias,
      },
    };
    this.setState({
      steps: Object.assign([], steps),
    });
  }

  handleOnAddOptions() {
    const steps = { ...this.state.steps };

    let subscriptionCopy = steps[0].data;

    this.props
      .dispatch(
        TucanoActions.addOptions(
          [subscriptionCopy.idOption],
          this.state.productId
        )
      )
      .then(() => {
        const { dataOnAddOptions, t } = this.props;
        if (dataOnAddOptions && dataOnAddOptions.newAuthToken) {
          this.handleOnAskPayment();
        } else {
          if (dataOnAddOptions && dataOnAddOptions.hasOwnProperty("code")) {
            this.props.dispatch(
              addToast({
                text: t(this.getErrorAPI("addOptions", dataOnAddOptions.code)),
                className: "error",
                duration: 6000,
              })
            );
            // }
          }
        }
      })
      .catch((_error) => {
        const { dataOnAddOptions, t } = this.props;
        if (dataOnAddOptions && dataOnAddOptions.hasOwnProperty("code")) {
          // if (dataOnAddOptions.code === -2333) {
          //   this.handleOnAskPayment();
          // } else {
          this.props.dispatch(
            addToast({
              text: t(this.getErrorAPI("addOptions", dataOnAddOptions.code)),
              className: "error",
              duration: 6000,
            })
          );
          // }
        }
      });
  }

  async handleOnAskPayment() {
    const steps = { ...this.state.steps };
    const { voucherOnlyOption } = this.state;
    const { accountDetail } = this.props;
    if (voucherOnlyOption === true) {
      this.selectSubscriptionPaymentBrand({ paymentBrand: "Stripe" });
    }
    let voucherCopy = steps[1].data;
    let paymentCopy = steps[2].data;
    let reuseData;
    let paymentData;
    let paymentExists;
    paymentExists = this.props.paymentMethods.filter((p) => {
      return p.paymentBrand === paymentCopy.paymentBrand;
    });
    if (paymentCopy.paymentMethod) {
      if (accountDetail && !accountDetail.hasPurchaseCode) {
        if (paymentExists && paymentExists.length > 0) {
          paymentCopy.paymentMethod = paymentExists[0].paymentMethod;
          paymentCopy.paymentMode = paymentCopy.paymentBrand.substring(
            0,
            paymentCopy.paymentBrand.length - 3
          );
          paymentCopy.paymentBrand = paymentCopy.paymentBrand.substring(
            0,
            paymentCopy.paymentBrand.length - 3
          );
        } else {
          paymentCopy.paymentMethod = "Stripe";
          paymentCopy.paymentMode = "Stripe";
          paymentCopy.paymentBrand = "Stripe";
        }
        reuseData = {
          reusePaymentInfo: false,
          purchasepin: false,
        };
        this.setState({ proceed: true });
      }
      if (accountDetail && accountDetail.hasPurchaseCode) {
        if (!this.state.proceed) {
          await this.ToggleModalPurchaseControl();
        } else {
          if (paymentExists && paymentExists.length > 0) {
            paymentCopy.paymentMethod = paymentExists[0].paymentMethod;
            paymentCopy.paymentMode = paymentCopy.paymentBrand.substring(
              0,
              paymentCopy.paymentBrand.length - 3
            );
            paymentCopy.paymentBrand = paymentCopy.paymentBrand.substring(
              0,
              paymentCopy.paymentBrand.length - 3
            );
          } else {
            paymentCopy.paymentMethod = "Stripe";
            paymentCopy.paymentMode = "Stripe";
            paymentCopy.paymentBrand = "Stripe";
          }
          await this.props
            .dispatch(TucanoActions.getPurchaseCode())
            .then((result) => {
              if (
                result.code === -3 &&
                accountDetail &&
                accountDetail.hasPurchaseCode
              ) {
                // this.setState({
                //   showPurchaseControl: true,
                // })
                reuseData = {
                  reusePaymentInfo: false,
                  purchasepin: this.state.purchaseCode,
                };
              } else if (result.newAuthToken) {
                reuseData = {
                  reusePaymentInfo: false,
                  purchasepin: this.state.purchaseCode,
                };
              }
            });
        }
      }
    } else {
      await this.setState({ proceed: true });
    }
    if (this.state.proceed) {
      paymentData = {
        paymentProvider: paymentCopy.brandBillingProviderId,
        policyValidation: true,
        paymentBrand: paymentCopy.paymentBrand,
        paymentMode: paymentCopy.paymentBrand,
        paymentMethod:
          paymentExists && paymentExists.length > 0
            ? paymentExists[0].paymentMethod
            : paymentCopy.paymentMethod,
        returnUrl: window.location.href,
        successUrl:
          window.location.origin +
          consts.routes.subscription.url +
          "/?mv=transaction_accept",
        cancelUrl:
          window.location.origin +
          consts.routes.subscription.url +
          "/?mv=transaction_cancel",
      };
      await this.props
        .dispatch(
          TucanoActions.callAskPayment(
            JSON.stringify(paymentData),
            reuseData ? reuseData.reusePaymentInfo : null,
            reuseData ? reuseData.purchasepin : null,
            voucherCopy.voucherCode
          )
        )
        .then((result) => {
          const { t } = this.props;
          if (
            result &&
            result.status &&
            result.payload &&
            result.payload.action !== null
          ) {
            checkOrderstatus(this.props.dispatch);
            paymentCopy.paymentMethod = paymentExists[0].paymentMethod;
            switch (paymentCopy.paymentMethod.toLowerCase()) {
              case "creditcard":
              case "paypal": {
                const ingenicoModel = new IngenicoModel();
                this.paymentForm.appendChild(
                  ingenicoModel.fillForm(result.payload, this.paymentForm)
                );

                this.paymentForm.setAttribute("target", "_blank");
                this.paymentForm.submit();
                break;
              }
              case "stripe":
              case "str": {
                this.openPaymentPopup(result.payload.action);
                break;
              }
              default: {
                break;
              }
            }
            this.handleClose();
          } else {
            this.setState({
              currentStep: 3,
            });

            if (
              result &&
              result.hasOwnProperty("code") &&
              result.code !== -500
            ) {
              this.props.dispatch(
                addToast({
                  text: t(this.getErrorAPI("askPayment", result.code)),
                  className: "error",
                  duration: 6000,
                })
              );
            }
          }
        })
        .catch((_error) => {
          // console.log(error);
          this.setState({
            currentStep: 3,
          });

          const { errorOnAskPayment, t } = this.props;
          if (errorOnAskPayment && errorOnAskPayment.hasOwnProperty("code")) {
            this.props.dispatch(
              addToast({
                text: t(this.getErrorAPI("askPayment", errorOnAskPayment.code)),
                className: "error",
                duration: 6000,
              })
            );
          }
        });
    }
  }

  openPaymentPopup(url) {
    const strWindowFeatures =
      "toolbar=no, menubar=no, width=600, height=700, top=100, left=100";
    if (
      typeof window.paymentPopup === "undefined" ||
      window.paymentPopup?.closed
    ) {
      window.paymentPopup = window.open(
        url,
        "Stripe Payment",
        strWindowFeatures
      );
    } else if (this.previousUrl !== url) {
      window.paymentPopup = window.open(
        url,
        "Stripe Payment",
        strWindowFeatures
      );
      window.paymentPopup.focus();
    } else {
      window.paymentPopup.focus();
    }
    // assign the previous URL
    this.previousUrl = url;
  }

  async handleOnBuyAsset() {
    this.setState({ isOld: true });
    const { dataOnTestAsset, accountDetail } = this.props;
    const steps = { ...this.state.steps };
    let subscriptionCopy = steps[0].data;
    let paymentCopy = steps[2].data;
    let paymentData;
    let reuseData;
    const paymentExists = this.props.paymentMethods.filter((p) => {
      return p.paymentBrand === paymentCopy.paymentBrand;
    });
    if (paymentCopy.paymentBrand) {
      if (accountDetail && !accountDetail.hasPurchaseCode) {
        if (paymentExists && paymentExists.length > 0) {
          paymentCopy.paymentMethod = paymentExists[0].paymentMethod;
          paymentCopy.paymentMode = paymentCopy.paymentBrand;
          paymentCopy.paymentBrand = paymentCopy.paymentBrand;
        } else {
          paymentCopy.paymentMethod = "Stripe";
          paymentCopy.paymentMode = "Stripe";
          paymentCopy.paymentBrand = "Stripe";
        }
        await this.setState({ proceed: true, currentStep: 3 });
        // await this.props
        //   .dispatch(TucanoActions.getPurchaseCode())
        //   .then((result) => {
        //     if(result.code === -3 && accountDetail && accountDetail.hasPurchaseCode) {
        //       this.setState({
        //         showPurchaseControl: true,
        //       })
        //     } else if (result.newAuthToken) {
        //       reuseData = {
        //         reusePaymentInfo: false,
        //         purchasepin: this.props.purchaseCode,
        //       };
        //       this.setState({ proceed: true });
        //     }
        //   });
      }
      if (accountDetail && accountDetail.hasPurchaseCode) {
        if (!this.state.proceed) {
          await this.ToggleModalPurchaseControl();
        } else {
          if (paymentExists && paymentExists.length > 0) {
            paymentCopy.paymentMethod = paymentCopy.paymentMethod.substring(
              0,
              paymentCopy.paymentMethod.length - 3
            );
            paymentCopy.paymentMode = paymentCopy.paymentBrand;
            paymentCopy.paymentBrand = paymentCopy.paymentBrand;
          } else {
            paymentCopy.paymentMethod = "Stripe";
            paymentCopy.paymentMode = "Stripe";
            paymentCopy.paymentBrand = "Stripe";
          }
          await this.props
            .dispatch(TucanoActions.getPurchaseCode())
            .then((result) => {
              if (
                result.code === -3 &&
                accountDetail &&
                accountDetail.hasPurchaseCode
              ) {
                // this.setState({
                //   showPurchaseControl: true,
                // })
                reuseData = {
                  reusePaymentInfo: false,
                  purchasepin: this.state.purchaseCode,
                };
              } else if (result.newAuthToken) {
                reuseData = {
                  reusePaymentInfo: false,
                  purchasepin: this.props.purchaseCode,
                };
              }
            });
        }
      }
    } else {
      await this.setState({ proceed: true });
    }
    if (this.state.proceed) {
      paymentData = {
        paymentProvider: paymentExists[0].brandBillingProviderId,
        policyValidation: true,
        paymentBrand: paymentCopy.paymentBrand,
        paymentMode: paymentCopy.paymentBrand,
        paymentMethod: paymentCopy.paymentMethod,
        imageType: consts.imageTypes.movie_episode_cover,
        returnUrl:
          window.location.origin +
          consts.routes.movieAsset.url.replace(":id", subscriptionCopy.id),
        successUrl:
          window.location.origin +
          consts.routes.movieAsset.url.replace(":id", subscriptionCopy.id) +
          "?mv=transaction_accept",
        cancelUrl:
          window.location.origin +
          consts.routes.movieAsset.url.replace(":id", subscriptionCopy.id) +
          "?mv=transaction_cancel",
      };
      this.props
        .dispatch(
          TucanoActions.buyAsset(
            subscriptionCopy.id,
            dataOnTestAsset.price,
            null,
            JSON.stringify(paymentData),
            reuseData ? reuseData.reusePaymentInfo : null,
            reuseData ? reuseData.purchasepin : null
          )
        )
        .then(async (result) => {
          const { errorOnBuyAsset, t, item, dispatch, history } = this.props;

          if (item && history.location.pathname.includes("player")) {
            let audioLang, subtitleLang;
            if (item.lang) {
              audioLang = item.lang[0].audio;
              subtitleLang = item.lang[0].sub;
            }
            await dispatch(
              TucanoActions.readAsset(item.id, audioLang, subtitleLang)
            );
          }
          if (
            result &&
            (result?.status || result?.action || result?.newAuthToken)
          ) {
            switch (paymentCopy.paymentMethod.toLowerCase()) {
              case "creditcard":
              case "paypal": {
                const ingenicoModel = new IngenicoModel();
                this.paymentForm.appendChild(
                  ingenicoModel.fillForm(result, this.paymentForm)
                );
                this.paymentForm.setAttribute("target", "_blank");
                this.paymentForm.submit();
                break;
              }
              case "stripe":
              case "str": {
                this.openPaymentPopup(result.action);
                sendAnalytics(
                  this.props.dispatch,
                  "INFO",
                  "8000",
                  "PAYMENT",
                  JSON.stringify({
                    gatewayName: paymentData.paymentBrand,
                    amount: dataOnTestAsset.price || null,
                    isoMonetaryUnit: consts.currency || null,
                  })
                );
                break;
              }
              default: {
                break;
              }
            }
            checkOrderstatus(this.props.dispatch);
          }

          if (errorOnBuyAsset && errorOnBuyAsset.hasOwnProperty("code")) {
            this.props.onCloseClick();
            this.props.dispatch(
              addToast({
                text: t(this.getErrorAPI("buyAsset", errorOnBuyAsset.code)),
                className: "error",
                duration: 6000,
              })
            );
          }
        })
        .catch((_error) => {
          if (this.state.isOld) {
            this.props.history.push(
              consts.routes.movieAsset.url.replace(":id", subscriptionCopy.id)
            );
          }
          this.setState({
            currentStep: 3,
          });
          const { errorOnBuyAsset, t } = this.props;
          if (errorOnBuyAsset && errorOnBuyAsset.hasOwnProperty("code")) {
            this.props.dispatch(
              addToast({
                text: t(this.getErrorAPI("buyAsset", errorOnBuyAsset.code)),
                className: "error",
                duration: 6000,
              })
            );
          }
        });
    }
  }
  closeModalPurchaseCode = () => {
    this.setState(
      { showPurchaseControl: !this.state.showPurchaseControl },
      () => {
        if (this.state.isConfirmOnly) {
          this.setState({ useCode: true }, () => {
            // FIXME: TO BE DEFINED
          });
        }
      }
    );
  };
  ToggleModalPurchaseControl = () => {
    this.setState({ showPurchaseControl: !this.state.showPurchaseControl });
  };
  // confirmModalPurchase = () => {
  //   this.setState({ showPurchaseControl: !this.state.showPurchaseControl, currentStep: 3 }, () => {
  //     this.setState({ proceed: true }, () => {
  //       if (!this.state.isConfirmOnly) this.handleOnAddSubscription();
  //     });
  //   });
  // };
  confirmModalPurchase = (pin) => {
    this.setState(
      {
        showPurchaseControl: !this.state.showPurchaseControl,
        purchaseCode: pin,
      },
      () => {
        this.setState({ proceed: true, currentStep: 3 }, () => {
          const steps = { ...this.state.steps };

          let subscriptionCopy = steps[0].data;

          if (subscriptionCopy instanceof TucanoModels.OptionAvailability) {
            this.handleOnAskPayment();
          }

          if (subscriptionCopy instanceof TucanoModels.Asset) {
            this.handleOnBuyAsset();
          }
          // if (!this.state.isConfirmOnly) this.handleOnAddSubscription();
        });
      }
    );
  };
  handleOnAddSubscription() {
    const steps = { ...this.state.steps };

    let subscriptionCopy = steps[0].data;

    if (subscriptionCopy instanceof TucanoModels.OptionAvailability) {
      this.handleOnAddOptions();
    }

    if (subscriptionCopy instanceof TucanoModels.Asset) {
      this.handleOnBuyAsset();
    }
  }

  render() {
    const {
      rootClassName,
      t,
      item,
      isLoadingAvailableOffers,
      availableOffers,
      isLoadingOnTestAsset,
      dataOnTestAsset,
      isLoadingListPaymentMethods,
      paymentMethods,
      isLoadingAskPayment,
      errorOnAskPayment,
      accountDetail,
      isLoadingBuyAsset,
      errorOnBuyAsset,
      isLoadingCheckOrderStatus,
      paymentStatus,
    } = this.props;

    const { currentStep, steps, endProcess } = this.state;
    const finalClassName = classnames(
      rootClassName,
      style.root,
      style.subscription
    );

    let objectId = null;
    let name = "";
    let price = null;
    let imgType = null;

    if (steps[0].data instanceof TucanoModels.OptionAvailability) {
      objectId = steps[0].data.getIdOption();
      name = steps[0].data.getName();
      price = steps[0].data.getPriceWithVat();
      imgType = consts.imageTypes.subscription;
    }
    if (steps[0].data instanceof TucanoModels.Asset) {
      objectId = steps[0].data.getId();
      name = steps[0].data.getTitle();
      price = dataOnTestAsset.price;
      imgType = consts.imageTypes.movie_episode_cover;
    }

    return (
      <Modal
        leftContent={{
          title:
            consts.appModules.subscription &&
            currentStep !== 3 &&
            currentStep !== -1
              ? t("Step") +
                " " +
                (currentStep + 1) +
                "/" +
                (steps.length + 1) +
                " " +
                t(steps[currentStep].name)
              : null,
        }}
        rightContent={{
          canDismiss: currentStep !== 3 && this.handleClose.bind(this),
        }}
        canDismiss={currentStep !== 3}
        overlay={true}
        rootClassName={finalClassName}
        isSubscription={true}
        buttons={
          <>
            {this.previousButton}
            {this.nextButton}
            {!endProcess && this.closeButton}
          </>
        }
      >
        <>
          {currentStep === -1 && (
            <span className={style.contentMessage}>
              <Trans>
                Before proceeding, Verify that your Safari pop-up settings are
                disabled,{" "}
              </Trans>
              <a
                style={{ textDecoration: "underline" }}
                href="App-prefs://prefs:root=SAFARI"
              >
                <Trans>SAFARI_SETTINGS_PATH</Trans>
              </a>
            </span>
          )}
          {currentStep === 0 && (
            <>
              {consts.appModules.subscription && !isLoadingAvailableOffers && (
                <p className={style.setupFeeDescription}>
                  *
                  <Trans>
                    Setup fees could be applied for some subscriptions if it is
                    mentioned with the offer price, only once at the first
                    subscription or at the subscription renewal after
                    cancellation.
                  </Trans>
                </p>
              )}
              {consts.appModules.subscription &&
                item instanceof TucanoModels.Asset &&
                item.isCatchupAsset === false &&
                item.getType() !== "channel_logo" && (
                  <>
                    {isLoadingOnTestAsset && <Loader />}
                    {!isLoadingOnTestAsset &&
                      dataOnTestAsset &&
                      dataOnTestAsset.alreadyBuy === false &&
                      dataOnTestAsset.price &&
                      parseInt(dataOnTestAsset.price) >= 0 && (
                        <>
                          <p className={style.label}>
                            <Trans>Rent this content</Trans>
                          </p>
                          <SubscriptionItem
                            rootClassName={style.subscriptionItem}
                            key={0}
                            subscriptionItem={item}
                            additionalInfoItem={dataOnTestAsset}
                            type={"offer"}
                            selectionType={"TEXT"}
                            onActionButtonClick={this.handleSubscription.bind(
                              this
                            )}
                          />
                          {availableOffers && availableOffers.length > 0 && (
                            <p className={style.label}>
                              <Trans>
                                Or subscribe to one of available offers
                              </Trans>
                            </p>
                          )}
                        </>
                      )}
                  </>
                )}
              {consts.appModules.subscription && isLoadingAvailableOffers && (
                <Loader />
              )}
              {consts.appModules.subscription && !isLoadingAvailableOffers && (
                <>
                  {availableOffers && availableOffers.length > 0 && (
                    <>
                      {availableOffers.map((item, index) => {
                        return (
                          <SubscriptionItem
                            rootClassName={style.subscriptionItem}
                            key={index}
                            subscriptionItem={item}
                            type={"offer"}
                            selectionType={"TEXT"}
                            onActionButtonClick={this.handleSubscription.bind(
                              this
                            )}
                          />
                        );
                      })}
                    </>
                  )}
                </>
              )}
              {consts.appModules.subscription !== true ? (
                <ErrorMessage
                  rootClassName={style.errorMessage}
                  isPopup={true}
                  message={t(
                    "Your subscription doesn't allow you to access to this content"
                  )}
                />
              ) : (
                !isLoadingAvailableOffers &&
                (!availableOffers ||
                  (availableOffers && availableOffers.length === 0)) &&
                (!dataOnTestAsset ||
                  (dataOnTestAsset && !dataOnTestAsset.price)) && (
                  <ErrorMessage
                    rootClassName={style.errorMessage}
                    message={t("No available offers")}
                  />
                )
              )}
              {}
            </>
          )}
          {currentStep === 1 && (
            <>
              <div className={style.imageContainer}>
                <Image
                  src={`${consts.image_hostname}${
                    routes.imgdata
                  }?type=${imgType}&objectId=${objectId}&ratio=169&format_w=400&format_h=222&languageId=${getFromLocal(
                    "languageId"
                  )}`}
                  rootClassName={style.image}
                  ratio={ImageRatio.COVER}
                  placeholder={PlaceholderType.SECONDARY}
                />
              </div>

              <div className={style.block}>
                <p className={style.title}>{name}</p>
                {steps[0].data instanceof TucanoModels.Asset ? (
                  <span className={style.price}>
                    {formatPrice(
                      price,
                      consts.priceAfter,
                      consts.priceDecimals,
                      consts.currency
                    )}{" "}
                    - {consts.rentalPeriod} <Trans>rental</Trans>
                  </span>
                ) : (
                  <span className={style.price}>
                    {formatPrice(
                      price,
                      consts.priceAfter,
                      consts.priceDecimals,
                      consts.currency
                    )}
                  </span>
                )}
                {steps[0].data instanceof TucanoModels.OptionAvailability && (
                  <>
                    <Collapsible
                      open={true}
                      trigger={
                        <>
                          <span>
                            <Trans>Details</Trans>
                          </span>
                          <ArrowHeading className={style.arrow} />
                        </>
                      }
                    >
                      <p
                        className={style.synopsis}
                        dangerouslySetInnerHTML={{
                          __html: steps[0].data.getSynopsis(),
                        }}
                      />
                    </Collapsible>
                    {consts.manageSubscription && (
                      <>
                        <VoucherZone
                          subscriptionItems={[steps[0].data]}
                          callback={this.setVoucherCode.bind(this)}
                        />
                      </>
                    )}
                  </>
                )}
              </div>
            </>
          )}
          {currentStep === 2 && price > 0 && (
            <>
              {accountDetail &&
                accountDetail.paymentType &&
                accountDetail.paymentHolder &&
                accountDetail.paymentCardNumber &&
                accountDetail.paymentExpirationDate && (
                  <RadioCard
                    type={"vertical"}
                    items={[
                      {
                        change: true,
                        paymentAlias: `${accountDetail.paymentType}Old`,
                        paymentBrand: `${accountDetail.paymentType}Old`,
                        paymentMethod:
                          accountDetail.paymentType !== "Visa" &&
                          accountDetail.paymentType !== "Mastercard"
                            ? `${accountDetail.paymentType}Old`
                            : "CreditCardOld",
                        paymentType: accountDetail.paymentType,
                        paymentHolder: accountDetail.paymentHolder,
                        paymentCardNumber: accountDetail.paymentCardNumber,
                        paymentExpirationDate:
                          accountDetail.paymentExpirationDate,
                      },
                    ]}
                    selected={steps[currentStep].data.paymentMethod}
                    callback={this.selectSubscriptionPaymentBrandOld.bind(this)}
                    classNameByKey={"paymentBrand"}
                  />
                )}
              {isLoadingListPaymentMethods && <Loader centered={true} />}
              {!isLoadingListPaymentMethods && (
                <>
                  {accountDetail &&
                  accountDetail.paymentType &&
                  accountDetail.paymentHolder &&
                  accountDetail.paymentCardNumber &&
                  accountDetail.paymentExpirationDate ? (
                    <Collapsible
                      open={true}
                      trigger={
                        <div className={style.arrowContainer}>
                          <span className={style.arrowTitle}>
                            <Trans>Other payment methods</Trans>
                          </span>
                          <ArrowHeading className={style.arrow} />
                        </div>
                      }
                    >
                      <RadioCard
                        type={"vertical"}
                        items={paymentMethods}
                        selected={steps[currentStep].data.paymentMethod}
                        callback={this.selectSubscriptionPaymentBrand.bind(
                          this
                        )}
                        classNameByKey={"paymentBrand"}
                      />
                    </Collapsible>
                  ) : (
                    <>
                      <RadioCard
                        type={"vertical"}
                        items={paymentMethods}
                        selected={steps[currentStep].data.paymentMethod}
                        callback={this.selectSubscriptionPaymentBrand.bind(
                          this
                        )}
                        classNameByKey={"paymentBrand"}
                      />
                      <div className={style.purchaseCodeArea}>
                        {" "}
                        <div>
                          <Checkbox
                            change={() => {}}
                            checked={true}
                            label={t("Save your payment method for later use")}
                            rootClassName={style.switch}
                          />
                          <div className={style.purchaseCodeLabel}>
                            {t("savingCode")}
                          </div>
                        </div>
                        <div>
                          <Checkbox
                            change={this.toggleUseCode}
                            checked={this.state.useCode}
                            label={<Trans>Use a purchase code</Trans>}
                            rootClassName={style.switch}
                          />
                          <div className={style.purchaseCodeLabel}>
                            <Trans>
                              The purchase code won't be requested for future
                              payment with your last used payment method if you
                              uncheck,Your default code is 1234
                            </Trans>
                          </div>
                        </div>
                        <div
                          className={style.purchaseCodeElement}
                          onClick={this.togglePurchaseCodeModal}
                        >
                          <Trans>Change your purchase code</Trans>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </>
          )}
          {currentStep === 3 && (
            <>
              {isLoadingAskPayment ||
              isLoadingBuyAsset ||
              isLoadingCheckOrderStatus ||
              endProcess ||
              (paymentStatus !== "executed" &&
                paymentStatus !== "cancelled") ? (
                <Loader
                  loaderMinHeightClassName={style.loaderMinHeightClassName}
                  centered={true}
                />
              ) : (
                <>
                  <>
                    {((errorOnAskPayment &&
                      errorOnAskPayment.code &&
                      errorOnAskPayment.code === -500) ||
                      (!isLoadingAskPayment && !errorOnAskPayment)) &&
                      !isLoadingBuyAsset &&
                      !errorOnBuyAsset && (
                        <>
                          <h1 className={style.infoTitle}>
                            <Trans>Awesome</Trans> !
                          </h1>

                          <span className={style.contentMessage}>
                            <Trans>
                              You can now enjoy all the package content
                            </Trans>
                          </span>
                        </>
                      )}
                  </>
                  <>
                    {((!isLoadingAskPayment &&
                      errorOnAskPayment &&
                      errorOnAskPayment.code &&
                      errorOnAskPayment.code !== -500) ||
                      (!isLoadingBuyAsset && errorOnBuyAsset)) && (
                      <>
                        <h1 className={style.infoTitle}>
                          <Trans>Something went wrong !</Trans>
                        </h1>

                        <span className={style.contentMessage}>
                          <Trans>
                            A problem occurred when subscribing,Please try again
                          </Trans>
                        </span>
                      </>
                    )}
                  </>
                </>
              )}
            </>
          )}
          {this.state.showPurchaseControl === true && (
            <ModalValidatePurchaseCode
              onCloseClick={this.closeModalPurchaseCode}
              onConfirmClick={this.confirmModalPurchase}
              requiresPayment={true}
              assetId={item.id}
            />
          )}
          {this.state.showEditPurchaseCode && (
            <Modal
              size={"sm"}
              leftContent={{ title: t("Change purchase code") }}
              rightContent={{
                canDismiss: this.togglePurchaseCodeModal.bind(this),
              }}
              rootClassName={style.modal}
              overlay={true}
            >
              <FormChangePurchaseCode
                closeModal={this.togglePurchaseCodeModal}
                rootClassName={style.modal}
              />
            </Modal>
          )}
        </>
      </Modal>
    );
  }
}

export default compose(
  connect((state, props) => {
    let availableOffers = undefined;
    let id = undefined;
    if (props.item instanceof TucanoModels.Asset) {
      id = props.item.packageIds;
      availableOffers = TucanoSelectors.selectAvailableOffersByPackageId(
        state,
        id
      );
    }
    if (
      props.item instanceof TucanoModels.Channel ||
      (props.item instanceof TucanoModels.Asset &&
        props.item.getType() === "channel_logo")
    ) {
      id = props.item.id;
      availableOffers = TucanoSelectors.selectAvailableOffersByChannelId(
        state,
        id
      );
    }
    if (
      props.item instanceof TucanoModels.EPG ||
      props.item instanceof TucanoModels.EPGEventAsset
    ) {
      id = props.item.channelId;
      availableOffers = TucanoSelectors.selectAvailableOffersByChannelId(
        state,
        id
      );
    }

    return {
      isConnected: state.session.customerAuthToken !== undefined,
      paymentMethods: TucanoSelectors.getListPaymentMethods(state),
      isLoadingAddOptions: state.subscription.addOptions.loading,
      dataOnAddOptions: state.subscription.addOptions.data || undefined,
      isLoadingAskPayment: state.subscription.askPayment.loading,
      errorOnAskPayment: state.subscription.askPayment.error || false,
      availableOffers: availableOffers,
      isLoadingAvailableOffers: state.subscription.availableOffers.loading,
      isLoadingOnTestAsset: state.player.testAsset.loading,
      dataOnTestAsset: state.player.testAsset.data || undefined,
      dataOnBuyAsset: state.player.buyAsset.data || undefined,
      isLoadingBuyAsset: state.player.buyAsset.loading,
      errorOnBuyAsset: state.player.errorBuyAsset || false,
      accountDetail: state.account.user.data,
      purchaseCode: state.account.purchaseCode.data,
      optionValidity: state.subscription.optionValidity.data,
      isLoadingValidateCode: state.account.validatePurchaseCode.loading,
      purchaseCodeValidity: state.account.validatePurchaseCode.data,
      isLoadingCheckOrderStatus: state.subscription.checkOrderStatus.loading,
      paymentStatus: state.subscription.checkOrderStatus.data?.orderStatus,
    };
  }),
  withTranslation()
)(withRouter(ModalSubscription));
